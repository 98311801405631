@import "~@wfp/ui/src/globals/scss/styles";
//@import "react-table/react-table.css";
@import "./react-table.scss";

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-color: #fbfcfc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1 1 auto;
  margin-bottom: 30px;
}

.info {
  font-style: italic;
}

.loading {
  display: flex;
  align-items: center;
}

.blockquote {
  margin-top: 20px;
  padding-bottom: 10px;
}

.infoInline {
  display: flex;
  font-style: italic;
}

.infoModule {
  padding-top: 0.65em;
  padding-left: 0;
  padding-right: 0;
}

.infoBodyModule {
  padding: 0.75em !important;
  font-size: 0.95em;
  line-height: 1.2em;
}

.infoCenterBodyModule {
  padding: 0.75em !important;
  font-size: 0.95em;
  line-height: 1.2em;
  text-align: center;
}

.marginMedium {
  margin-top: 15px;
}

.marginSmall {
  margin-top: 10px;
}

.MyReactTableClass {
  .-sort-desc {
    box-shadow: none !important;
    &:before {
      content: "▼";
      float: right;
    }
  }

  .-sort-asc {
    box-shadow: none !important;
    &:before {
      content: "▲";
      float: right;
    }
  }
}

.alert {
  font-size: 0.75rem;
  position: inherit;
  max-width: 404px;
  margin-bottom: 20px;
}

/*
.logout {
  padding: 0 1.5rem;
  background-color: #fbfcfc;
  border-color: #fbfcfc;
  color: #0b77c2;
}*/

.footer-mail {
  color: black;
}

.footer {
  z-index: 0;
}

.modalRow {
  display: flex;
  flex-direction: column;
}

.modalButton {
  margin: 5px;
  float: right;
}

.searchTextInput {
  max-height: 38px;
}

.icon {
  cursor: pointer;
}

.guideMargin {
  margin-left: 15px;
}

.buttonContainer {
  display: flex;
}

.buttonRight {
  margin-left: auto;
}

.wfp--pagination {
  border: none !important;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}

.col-xs {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

@media (min-width: 332px) {
  .organization-select-container {
    flex: 0 0 33%;
    max-width: 33%;
    flex-basis: 33%;
    flex-grow: 0;
  }
  
  .organization-select-container .wfp--react-select__menu {
    min-width: 100%;
    width: max-content;
  }  
}
